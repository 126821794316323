<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to="/profile/user_info">
            <i class="iconfont icon-fanhui"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.password") }}</li>
      </ul>
    </div>
    <div class="main main_page">
      <div class="Sys_form">
        <AForm
          layout="vertical"
          autocomplete="off"
          @submit.prevent="handleConfirm"
        >
          <h3>{{ t("profile.label.changePassword") }}</h3>
          <div class="box">
            <ul>
              <li>{{ t("profile.label.oldPassword") }}</li>
              <li>
                <AFormItem name="oldpwd" v-bind="validateInfos.oldpwd">
                  <AInput
                    type="password"
                    v-model:value="formModel.oldpwd"
                    :placeholder="t('profile.placeholder.oldPassword')"
                    @blur="
                      validate('oldpwd', { trigger: 'blur' }).catch(() => {})
                    "
                  />
                </AFormItem>
              </li>
            </ul>
            <ul>
              <li>{{ t("profile.label.newPassword") }}</li>
              <li>
                <AFormItem name="newpwd" v-bind="validateInfos.newpwd">
                  <AInput
                    type="password"
                    v-model:value="formModel.newpwd"
                    :placeholder="t('profile.placeholder.newPassword')"
                    @blur="
                      validate('newpwd', { trigger: 'blur' }).catch(() => {})
                    "
                  />
                </AFormItem>
              </li>
            </ul>
            <ul>
              <li>{{ t("profile.label.confirmPasswordLower") }}</li>
              <li>
                <AFormItem name="confirmpwd" v-bind="validateInfos.confirmpwd">
                  <AInput
                    type="password"
                    v-model:value="formModel.confirmpwd"
                    :placeholder="t('profile.placeholder.confirmPassword')"
                    @blur="
                      validate('confirmpwd', {
                        trigger: 'blur',
                      }).catch(() => {})
                    "
                  />
                </AFormItem>
              </li>
            </ul>
          </div>
          <div class="Sys_tips c-999">
            {{ t("extra.rememberPwdText") }}
          </div>
          <div class="Sys_submit_btn password Sys_submit_btn_c">
            <button :disabled="!validateStatus" type="submit">
              {{ t("common.button.saveInformation") }}
            </button>
          </div>
        </AForm>
      </div>
    </div>
  </div>
  <Loading :loading="loading" />
</template>

<script>
import { ref, reactive, computed } from "vue";
import { Form } from "ant-design-vue";
const useForm = Form.useForm;
import { useRouter } from "vue-router";
import Loading from "@/components/Loading.vue";
import { useI18n } from "@/lang";
import api from "@/api";
export default {
  components: { Loading },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const formModel = reactive({ oldpwd: "", newpwd: "", confirmpwd: "" });

    let loading = ref(false);

    let validatePass = async (rule, value) => {
      if (value === "") {
        return Promise.reject(t("profile.placeholder.confirmPassword"));
      } else if (value !== formModel.newpwd) {
        return Promise.reject(t("profile.placeholder.passwordMath"));
      } else {
        return Promise.resolve();
      }
    };

    const ruleRef = reactive({
      oldpwd: [
        {
          required: true,
          message: t("profile.placeholder.oldPassword"),
          trigger: "blur",
        },
        {
          min: 6,
          max: 30,
          message:
            t("extra.minLength", { min: 6 }) +
            t("extra.maxLength", { max: 30 }),
          trigger: "blur",
        },
      ],
      newpwd: [
        {
          required: true,
          message: t("profile.placeholder.newPassword"),
          trigger: "blur",
        },
        {
          min: 6,
          max: 30,
          message:
            t("extra.minLength", { min: 6 }) +
            t("extra.maxLength", { max: 30 }),
          trigger: "blur",
        },
      ],
      confirmpwd: [
        {
          validator: validatePass,
        },
        {
          required: true,
          message: t("profile.placeholder.confirmPassword"),
        },
        {
          min: 6,
          max: 30,
          message:
            t("extra.minLength", { min: 6 }) +
            t("extra.maxLength", { max: 30 }),
          trigger: "blur",
        },
      ],
    });

    const { validate, validateInfos } = useForm(formModel, ruleRef);

    const validateStatus = computed(() => {
      if (
        validateInfos.oldpwd?.validateStatus == "success" &&
        validateInfos.newpwd?.validateStatus == "success" &&
        validateInfos.confirmpwd?.validateStatus == "success"
      ) {
        return true;
      } else {
        return false;
      }
    });

    function handleConfirm() {
      validate().then(
        async () => {
          submitHandle();
        },
        async (va) => {
          if (va.errorFields.length === 0) {
            submitHandle();
          }
        }
      );
    }

    function submitHandle() {
      loading.value = true;
      let formToSend = {
        newPwd: formModel.newpwd,
        oldPwd: formModel.oldpwd,
      };
      api
        .updatePwd(formToSend)
        .then((res) => {
          loading.value = false;
          if (res?.data?.success) {
            router.replace("/profile/user_info");
          }
        })
        .catch(() => {
          loading.value = false;
        });
    }

    return {
      t,
      formModel,
      ruleRef,
      handleConfirm,
      loading,
      validate,
      validateStatus,
      validateInfos,
    };
  },
};
</script>

<style lang="scss">
.ant-input {
  border-bottom: none !important;
}

.ant-input:focus {
  border-bottom: none !important;
}

.ant-form-item-children input:focus {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
}

.ant-form-vertical .ant-form-explain {
  // margin-top: -15px !important;
}

.Sys_submit_btn.password {
  button {
    &[disabled] {
      background: #a0a0a0;
    }
  }
}
</style>
